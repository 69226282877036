// @ts-nocheck
'use strict';
import Swiper from 'swiper/bundle';

var activeIndex = $(".swiper.category-list .swiper-slide.active").index() || 0;

var mySwiper = new Swiper(".category-list", {
    direction: 'horizontal',
    slidesPerView: 3,
    spaceBetween: 20,
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 0,
    freeMode: true,
    preloadImages: false,
    watchSlidesVisibility: false,
    initialSlide: activeIndex,
    navigation: {
        nextEl: ".home-categories .swiper-button-next",
        prevEl: ".home-categories .swiper-button-prev",
    },
    breakpoints: {
        768: {
            slidesPerView: 3
        },
        1024: {
            slidesPerView: 4
        },
    }
});

var swiperSubCategory = new Swiper(".swiper.sub-category", {
    direction: 'horizontal',
    slidesPerView: 'auto',
    spaceBetween: 15,
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 30,
    freeMode: true,
    preloadImages: false,
    watchSlidesVisibility: true,
});