// @ts-nocheck
'use strict';
import Swiper from 'swiper/bundle';

var mySwipers = new Swiper(".main-banner-carousel", {
    direction: 'horizontal',
    slidesPerView: 1,
    spaceBetween: 0,
    autoplay: true,
    pagination: {
        el: '.main-banner-carousel .swiper-pagination',
    }
});

$('.video').on('play', function () {
    mySwipers.autoplay.stop();
});

$('.video').on('ended, pause', function () {
    mySwipers.autoplay.start();
});

mySwipers.on('slideChange', function () {
    var slide = mySwipers.slides[mySwipers.activeIndex];
    var currentVideo = ($(slide).find('video'));
    if (currentVideo.length) {
        currentVideo.trigger('play');
    } else {
        currentVideo.trigger('pause');
    }
});